<template>
  <div>
    <div class="table-page-title">
      <div class="fl">
        <el-form :model="searchForm" class="search-form" :inline="true">
          <el-form-item label="" prop="weChatName">
            <el-input class="small-input" v-model="searchForm.weChatName" placeholder="用户昵称"></el-input>
          </el-form-item>
          <el-form-item label="" prop="mobile">
            <el-input class="small-input" v-model="searchForm.mobile" placeholder="手机号"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="fl search-form-btn">
        <el-button class="fl" size="small" type="primary" @click="search()">搜 索</el-button>
        <el-button class="fl" size="small" @click="reset()">重 置</el-button>
      </div>
    </div>
    <div class="table-container">
      <el-table
          class="table"
          height="100%"
          v-loading="tableLoading"
          :data="tableData">
<!--        <el-table-column-->
<!--            align="center"-->
<!--            header-align="center"-->
<!--            label="id"-->
<!--            :formatter="commonFormatter"-->
<!--            prop="id">-->
<!--        </el-table-column>-->
        <el-table-column
            fixed="left"
            align="center"
            header-align="center"
            label="微信昵称"
            prop="weChatName"
            :formatter="commonFormatter"
        >
        </el-table-column>
        <el-table-column
            align="center"
            header-align="center"
            label="手机号"
            prop="mobile"
            :formatter="commonFormatter"
        >
        </el-table-column>
        <el-table-column
            align="center"
            header-align="center"
            label="授权时间"
            prop="createTime"
            :formatter="commonFormatter"
        >
        </el-table-column>
        <el-table-column
            align="center"
            header-align="center"
            label="最近订单"
            prop="orderTime"
            :formatter="commonFormatter"
        >
        </el-table-column>
<!--        <el-table-column-->
<!--            align="center"-->
<!--            header-align="center"-->
<!--            label="订单数"-->
<!--        >-->
<!--          <template slot-scope="scope">-->
<!--            <span class="link_word" @click="goOrder(scope.row.weChatName)">{{ scope.row.orderCount }}</span>-->
<!--          </template>-->
<!--        </el-table-column>-->
        <el-table-column
            align="center"
            header-align="center"
            label="登录方式"
            prop="loginWay"
            :formatter="commonFormatter"
        >
          <template slot-scope="{row}">
            {{ row.method === 1 ? '微信登录' : row.method === 0 ? '账号登录' : '-' }}
          </template>
        </el-table-column>
<!--        <el-table-column-->
<!--            align="center"-->
<!--            header-align="center"-->
<!--            label="登录次数"-->
<!--            prop="loginCount"-->
<!--            :formatter="commonFormatter"-->
<!--        >-->
<!--        </el-table-column>-->
      </el-table>
    </div>
    <el-pagination
        class="top_10 right right_10"
        :current-page="pageNum"
        :page-sizes="pageSizes"
        :page-size="pageSize"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
    </el-pagination>
  </div>
</template>

<script>
import tableUse from "@/mixins/tableUse";

export default {
  name: "UserList",
  mixins: [tableUse],
  data() {
    return {
      searchForm: {
        weChatName: '',
        mobile: ''
      },
      tableData: [],
    }
  },
  methods: {
    reset() {
      this.searchForm = {
        weChatName: '',
        mobile: ''
      }
      this.search();
    },
    goOrder(name) {
      this.$router.push({
        name: 'OrderList',
        params:{
          name
        }
      })
    },
    getTableData() {
      this.tableLoading = true;
      let formData = {
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      if (this.searchForm.weChatName) formData.weChatName = this.searchForm.weChatName;
      if (this.searchForm.mobile) formData.mobile = this.searchForm.mobile;
      this.$axios.post("/sys-api/user-api/getUser", formData, (res) => {
        this.tableLoading = false;
        if (res.code === '100') {
          this.total = res.data.total;
          this.tableData = res.data.list;
        }
      })
    }
  },
  mounted() {
    this.getTableData();
  }
}
</script>

<style scoped lang="scss">

</style>
